import {
  Alert,
  AppLayout,
  Box,
  Button,
  Checkbox,
  Modal,
  SideNavigation,
  SpaceBetween
} from '@cloudscape-design/components';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
// Page components
import { MainPage } from './Screens/Components/landingPage/MainPage';
import { DocumentTableView } from './Screens/DocumentTableView';
import { TopNavigationBar } from './Screens/Components/TopNavigation';
import { DocListHelpPanel } from './Screens/sidePanel/doclistHelpPanel';
import { UploadHelpPanel } from './Screens/sidePanel/uploadSidePanel';
import { DocumentContext } from './context/documentContext';
import { UploadCSV } from './Screens/Components/UploadCSV';
// import { BaseLayout } from './Screens/Components/SynopsisPage1';
// import { DetailPage1 } from './Screens/Components/TopicOverview';
// sidepanels
import { ViewerSidePanel } from './Screens/sidePanel/docviewerSidePanel';
import { useContext, useEffect, useState } from 'react';
import { ProtectedRoute } from './Screens/ProtectedRoute';
import { AdminRoute } from './Screens/AdminRoute';
import { EditDomains } from './Screens/Components/EditDomainControls';
import { ChangePassword } from './Screens/Components/ChangePassword';
import { Reports } from './Screens/Components/reports';
import { UserContext } from './context/authContext';
import { ErrorReports } from './Screens/Components/errorReportPage';
import { FreeformAnalysis } from './Screens/Components/freeformAnalysis';
import { ClipboardSidePanel } from './Screens/sidePanel/copyToClipboardPanel';
import './App.css';
import { KnowledgeBaseSplash } from './Screens/Components/KnowledegeBaseSplash';
import { DocumentViewerQuestionaire } from './Screens/DocumentViewerQuestionaire';
import { QuestionaireSidePanel } from './Screens/sidePanel/questionaireSideView';
import { DocumentLayoutViewer } from './Screens/DocumentLayoutViewer';
// import 'pdfjs-dist/build/pdf.worker.min.mjs';
import { pdfjs } from 'react-pdf';
import { ReportsView } from './Screens/Components/reportsView';
import { ProjectTableView } from './Screens/projectTableView';
import { DocumentViewerContainer } from './Screens/DocumentViewerContainer';
import { ErrorBoundary } from 'react-error-boundary';
import { UploadDocument } from './Screens/Components/uploadViews/UploadDoc';
import { ProjectBreadcrumbs } from './Screens/breadCrumbs/projectBreadcrumbs';
import { DocumentBreadcrumbs } from './Screens/breadCrumbs/documentViewBreadCrumbs';
import { CheckBox } from 'docx';
import { CreateAccount } from './Screens/Components/accountViews/CreateAccount';
import { CostCalculator } from './Screens/Components/accountViews/costCalulator';
import { text } from 'stream/consumers';
import YouTubeVideo from './Screens/Components/tutorial/sample';
import { Videos } from './Screens/Components/tutorial/videos';
import { UnauthorizedScreen } from './Screens/Components/errorScreen/unauthorizedScreen';
import { ManageUsers } from './Screens/Components/users/ManageUsers';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export function App() {
  const {
    selectedItems,
    freeformSelectedItemsKB,
    freeformSelectedItemsQA,
    mainAlert,
    setMainAlert
  } = useContext(DocumentContext);
  const { user, updateTNC, latestTNCAcknowledged, userChecksComplete } =
    useContext(UserContext);
  const windowWidth = window.innerWidth;
  const [navItems, setNavItems] = useState<any[]>([
    // { type: 'link', text: 'Analyze Documents', href: 'upload' },
    // {
    //   type: 'link',
    //   text: 'Answer Questionnaires & Surveys',
    //   href: 'upload?type=questionnaire'
    // },
    // { type: 'link', text: 'List Documents', href: 'documents' },
    { type: 'link', text: 'Projects', href: 'projects' },
    { type: 'link', text: 'Search', href: 'freeform' },
    { type: 'divider' }
  ]);
  const [toolsOpen, setToolsOpen] = useState<boolean>(false);
  const [navOpen, setNavOpen] = useState<boolean>(true);
  const [mainContainerWidth, setMainContainerWith] = useState<number>(90);
  const [showNoticeModal, setShowNoticeModal] = useState<boolean>(false);
  const [noticeAccepted, setNoticeAccepted] = useState<boolean>(false);
  const navigate = useNavigate();
  let location = useLocation();
  useEffect(() => {
    if (user.isAdmin) {
      const temp = navItems;
      const adminSection = {
        type: 'section',
        text: 'Admin',
        items: [
          { type: 'link', text: 'Populate Database', href: 'admin/uploadcsv' },
          { type: 'link', text: 'Edit Controls / Domains', href: 'admin/edit' },
          { type: 'link', text: 'Manage Users', href: 'admin/users' },
          { type: 'link', text: 'Error Logs', href: 'admin/errorlogs' },
          { type: 'link', text: 'Create Account', href: 'admin/account' },
          {
            type: 'link',
            text: 'Cost Calculator',
            href: 'admin/costcalculator'
          },
          { type: 'link', text: 'tutorial', href: 'admin/tutorial' }
        ]
      };
      temp.push(adminSection);
      console.log('temp side nav', temp);
      setNavItems([...temp]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.isAdmin]);
  // useEffect(() => {
  //   if (
  //     selectedItems.length > 0 &&
  //     !location.pathname.includes('questionnaire')
  //   ) {
  //     setToolsOpen(true);
  //   }
  // }, [selectedItems]);
  useEffect(() => {
    if (
      (freeformSelectedItemsKB.length > 0 ||
        freeformSelectedItemsQA.length > 0) &&
      location.pathname === '/app/freeform'
    ) {
      setToolsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [freeformSelectedItemsKB, freeformSelectedItemsQA]);
  useEffect(() => {
    if (toolsOpen) {
      setNavOpen(false);
    }
  }, [toolsOpen]);
  useEffect(() => {
    if (location.pathname.includes('questionnaire')) {
      setToolsOpen(false);
    }
  }, [location.pathname, setToolsOpen]);
  useEffect(() => {
    if (location.pathname.includes('base')) {
      setNavOpen(false);
      setMainContainerWith(95);
    } else {
      setMainContainerWith(75);
    }
    if (location.pathname.includes('videos')) {
      setMainContainerWith(95);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (userChecksComplete) {
      if (!latestTNCAcknowledged && userChecksComplete) {
        setShowNoticeModal(true);
      } else {
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userChecksComplete]);

  const handleAcceptNotice = async () => {
    try {
      const result = await updateTNC();
      console.log('result', result);
      setMainAlert({
        alertType: 'success',
        showAlert: true,
        message: 'Successfully updated'
      });
      setShowNoticeModal(false);
    } catch (e) {
      const error = e as Error;
      setMainAlert({
        alertType: 'error',
        showAlert: true,
        message: error.message
      });
      setShowNoticeModal(false);
    }
  };

  return (
    <>
      <div
        style={{
          // position: 'fixed',
          top: 0,
          width: '100%',
          overflowY: 'scroll'
        }}
      >
        <TopNavigationBar />

        <AppLayout
          // minContentWidth={windowWidth * 0.65}
          disableContentPaddings
          headerSelector=".b"
          toolsWidth={400}
          toolsOpen={toolsOpen}
          onToolsChange={({ detail }) => setToolsOpen(detail.open)}
          navigationOpen={navOpen}
          onNavigationChange={() => {
            const newState = !navOpen;
            setNavOpen(newState);
          }}
          // maxContentWidth={Number.MAX_VALUE}
          navigation={
            <SideNavigation
              header={{ href: 'landing', text: 'Services' }}
              items={navItems}
              onFollow={(event) => {
                if (!event.detail.external) {
                  event.preventDefault();
                  navigate(`${event.detail.href}`);
                }
              }}
            />
          }
          content={
            <div
              style={{
                width: `${mainContainerWidth}%`,
                // paddingLeft: '1%',
                // maxHeight: '90vh',
                // alignItems: 'center',
                // alignContent: 'center',
                // justifyContent: 'center',
                margin: '1% auto',
                overflowY: 'scroll',
                scrollbarWidth: 'none'
              }}
            >
              {mainAlert.showAlert ? (
                <Alert
                  type={mainAlert.alertType}
                  dismissible
                  onDismiss={() =>
                    setMainAlert({
                      showAlert: false,
                      message: '',
                      alertType: 'success'
                    })
                  }
                >
                  {mainAlert.message}
                </Alert>
              ) : null}
              <Routes>
                {/* <Route element={<ProtectedRoute />}> */}
                <Route path="landing" element={<MainPage />} />
                <Route
                  path="projects/:projectId?"
                  element={<ProjectTableView />}
                />
                <Route
                  path="projects/:projectId?/folder/:folderId?"
                  element={<DocumentViewerContainer />}
                />
                <Route path="upload" element={<UploadDocument />} />
                {/* <Route path="documents" element={<DocumentTableView />} /> */}
                <Route path="reports" element={<ReportsView />} />
                <Route path="changepassword" element={<ChangePassword />} />
                <Route path="freeform" element={<FreeformAnalysis />} />
                <Route path="videos" element={<Videos />} />
                <Route
                  path="tutorial"
                  element={<YouTubeVideo videoId={''} />}
                />
                {/* <Route path="base" element={<BaseLayout />} /> */}
                <Route
                  path="questionnaire"
                  element={
                    <DocumentViewerQuestionaire
                      setShowSynopsis={function (): void {
                        throw new Error('Function not implemented.');
                      }}
                    />
                  }
                />
                <Route path="splash" element={<KnowledgeBaseSplash />} />

                <Route
                  path="base"
                  element={
                    <ErrorBoundary
                      fallback={
                        <p>Whoops, please refresh you browser Window.</p>
                      }
                    >
                      <DocumentLayoutViewer />
                    </ErrorBoundary>
                  }
                />
                {<Route path="authError" element={<UnauthorizedScreen />} />}
                {/* <Route path="filtered" element={<DocumentViewerFiltered  />} /> */}
                {/* </Route> */}
                <Route path="/admin/*" element={<AdminRoute />}>
                  <Route path="uploadcsv" element={<UploadCSV />} />
                  <Route path="edit" element={<EditDomains />} />
                  <Route path="users" element={<ManageUsers />} />
                  <Route path="errorlogs" element={<ErrorReports />} />
                  <Route path="account" element={<CreateAccount />} />
                  <Route path="costcalculator" element={<CostCalculator />} />
                </Route>
              </Routes>
            </div>
          }
          tools={
            <>
              <Routes>
                <Route path="upload" element={<UploadHelpPanel />} />
                <Route path="documents" element={<DocListHelpPanel />} />
                <Route path="base" element={<ViewerSidePanel />} />
                <Route path="freeform" element={<ClipboardSidePanel />} />
                <Route path="projects" element={<DocListHelpPanel />} />å
                <Route
                  path="questionnaire"
                  element={<QuestionaireSidePanel />}
                />
              </Routes>
            </>
          }
          breadcrumbs={
            <>
              <Routes>
                {/* <Route path="projects" element={<ProjectBreadcrumbs />} /> */}
                <Route
                  path="projects/:projectId?/folder/:folderId?"
                  element={<ProjectBreadcrumbs />}
                />
                <Route path="/base" element={<DocumentBreadcrumbs />} />
              </Routes>
            </>
          }
        />
      </div>
      <Modal
        visible={showNoticeModal}
        size="large"
        footer={
          <SpaceBetween size={'xxxs'}>
            <Button
              onClick={() => handleAcceptNotice()}
              disabled={!noticeAccepted}
            >
              Accept
            </Button>
          </SpaceBetween>
        }
      >
        {/* <SpaceBetween size={'s'} direction="vertical"> */}
        <>
          <h3>{`Welcome to the ${process.env.REACT_APP_TITLE}`}</h3>
          <p>Terms and Conditions</p>
          <div
            style={{
              maxHeight: '200px',
              overflowY: 'scroll'
            }}
          >
            <pre style={{ whiteSpace: 'pre', margin: '0' }}>
              {`
Terms and Conditions Last Updated: [DATE]
1. Definitions
"Service" refers to [YOUR COMPANY NAME]'s document processing and 
storage software-as-a-service "Customer" means any business
or individual using the Service "User" means any individual
accessing the Service on behalf of a Customer "Document" means
any file, text, image, or other content processed or stored
through the Service "Account" means a registered user account
with [YOUR COMPANY NAME] 
2. Account Terms 
2.1 Eligibility Users
must be at least 18 years old Users must provide accurate,
current, and complete registration information Business accounts
must be created by authorized representatives 
2.2 Account
Security Users are responsible for maintaining password
confidentiality Users must notify us immediately of any
unauthorized access Account sharing is prohibited Multi-factor
authentication may be required Customer is responsible for all
activities under their account 
3. Service Description 
3.1 Document Processing Description of document processing
capabilities File type limitations and size restrictions
Processing timeframes and queue management Storage duration and
limits 
3.2 Service Availability Uptime commitment: [specify,
e.g., 99.9%] Scheduled maintenance windows Emergency maintenance
provisions Service level agreements (SLAs) 
4. Data Protection and Security 
4.1 Data Storage Geographic location of data
storage Data encryption standards Backup procedures and
frequency Data retention policies 
4.2 Security Measures Access
controls and authentication Security monitoring and auditing
Incident response procedures Security breach notifications 
4.3 Privacy Compliance GDPR compliance measures CCPA compliance
measures Other relevant privacy regulations Data processing
agreements 
5. Customer Obligations 
5.1 Acceptable Use Prohibited content types Usage limitations API rate 
limits Storage quotas
5.2 Customer Responsibilities Content accuracy and legality User
access management Security best practices Compliance with
applicable laws 
6.   Payment Terms 
6.1  Fees Subscription tiers and
     pricing Usage-based charges Payment methods Billing cycles 
6.2  Payment Processing Late payment penalties Failed payment
     procedures Refund policies Price change notifications 
7.   Intellectual Property Rights 
7.1  Ownership Customer retains ownership of uploaded documents 
     Service provider owns platform and technology License grants for 
     service usage Restrictions on service modification 
7.2  License Terms Scope of granted license Usage restrictions 
     Term of license Termination conditions 
8.   Confidentiality 
8.1  Confidential Information Definition of confidential 
     information Protection requirements Permitted disclosures 
     Duration of obligations 
9.   Limitation of Liability
9.1  Warranty Disclaimers Service provided "as is" No guarantee
     of continuous service Accuracy limitations Third-party service
     disclaimers 
9.2  Liability Caps Maximum liability amount Excluded
     damages Force majeure provisions Indemnification obligations 
10.  Term and Termination 
10.1 Duration Initial term Renewal terms Termination notice 
     requirements Effect of termination 
10.2 Post-Termination Data retrieval period Data deletion procedures
     Final payment obligations Surviving provisions 
11. Modifications to Terms Right to modify terms Notice 
    requirements Acceptance mechanisms Rejection consequences 
12. Governing Law and Jurisdiction Applicable law Jurisdiction for disputes
    Arbitration provisions Class action waiver 
13. Contact Information [YOUR COMPANY NAME] 
    Email: [CONTACT EMAIL] 
    Address: [COMPANY ADDRESS] 
    Phone: [CONTACT PHONE] 
Acceptance By accessing or using the Service, you acknowledge that you have read,
understood, and agree to be bound by these Terms and Conditions.
[Signature/Acceptance Mechanism] 
Date: [AUTO-POPULATED] 
Company Name: [IF APPLICABLE]
Authorized Representative: [IF APPLICABLE]`}
            </pre>
          </div>
          <Checkbox
            onChange={({ detail }) => setNoticeAccepted(detail.checked)}
            checked={noticeAccepted}
          >
            I have read and understand the above text.
          </Checkbox>
          <p>Privacy Policy</p>
          <div style={{ maxHeight: '200px', overflowY: 'scroll' }}>
            <pre style={{ whiteSpace: 'pre', margin: '0' }}>
              {`
Privacy Policy
Last Updated: [DATE]
Introduction
[COMPANY NAME] ("we," "us," or "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your personal data when you use our document processing and storage software-as-a-service, including our website, applications, and related services (collectively, the "Services").
1. Data Collection
1.1 Personal Data You Provide Directly

Account Information: Name, email address, phone number, company details
Payment Information: Billing information and payment details
Document Content: Any documents, files, or content you upload for processing
User Feedback: Ratings, comments, and improvement suggestions
Communication Data: Information from customer support interactions

1.2 Automatically Collected Data

Device Information: Device type, operating system, browser type
Usage Data: Access times, features used, processing requests
Technical Information: IP addresses, log files, error reports
Performance Data: Processing times, queue status, error rates
Cookies and Similar Technologies: As detailed in our Cookie Policy [LINK]

2. Use of Personal Data
We use your personal data for the following purposes:
2.1 Service Provision

Processing and storing your documents
Managing your account
Processing payments
Providing customer support
Sending service notifications

2.2 Service Improvement

Analyzing usage patterns
Debugging and optimization
Feature development
Quality assurance
Performance monitoring

2.3 Security and Compliance

Protecting against unauthorized access
Detecting and preventing fraud
Maintaining audit trails
Complying with legal obligations
Enforcing our terms of service

3. Data Sharing and Disclosure
We may share your personal data with:
3.1 Service Providers

Cloud storage providers
Payment processors
Analytics services
Customer support tools
Security services

3.2 Legal Requirements

Court orders
Legal proceedings
Regulatory requirements
Government requests
Protection of legal rights

4. Data Security
4.1 Security Measures

Encryption in transit and at rest
Access controls
Security monitoring
Regular security assessments
Incident response procedures

4.2 Data Retention

Active account data: Duration of account plus [X] days
Processed documents: [X] days after processing
Payment information: As required by law
Usage logs: [X] months
Backup retention: [X] days

5. Your Rights and Choices
5.1 Access Rights

Review your personal data
Download your documents
Export your processing history
Access usage logs

5.2 Control Rights

Update account information
Delete documents
Close account
Opt-out options
Marketing preferences

6. International Data Transfers

Data storage locations
Cross-border transfer mechanisms
International processing safeguards
Applicable data protection standards

7. Children's Privacy

Minimum age requirements
Parental consent procedures
Data deletion policy for minors
Protection measures

8. Region-Specific Disclosures
8.1 European Users (GDPR)

Legal bases for processing
Additional rights
Data transfer mechanisms
Representative information

8.2 California Users (CCPA)

Additional rights
Categories of personal information
Sharing practices
Opt-out procedures

9. Changes to Privacy Policy

Update notification process
Material change communications
Prior version access
Effective date tracking

10. Contact Information
[COMPANY NAME]
Email: [PRIVACY@COMPANY.COM]
Address: [COMPANY ADDRESS]
Phone: [CONTACT NUMBER]
Data Protection Officer: [DPO@COMPANY.COM]
11. Document Processing Specific Provisions
11.1 Document Handling

Document retention periods
Processing workflow privacy
Content extraction policies
Metadata handling

11.2 Content Privacy

Document access controls
Content segregation
Processing isolation
Data minimization

11.3 Deletion and Removal

Document deletion process
Backup removal
Permanent erasure
Recovery limitations

            `}
            </pre>
          </div>
        </>
        <Checkbox
          onChange={({ detail }) => setNoticeAccepted(detail.checked)}
          checked={noticeAccepted}
        >
          I have read and understand the above text.
        </Checkbox>
        {/* </SpaceBetween> */}
      </Modal>
    </>
  );
}
